<template>
  <b-form @submit.prevent="onSubmit">



    <b-form-group
      label="Thumbnail:"
      label-for="input-0"
    >
      <img
        style="width: 200px"
        v-bind:src="form.Thumbnail"
      /><br /><br />
      <form action="#">
        <input
          type="file"
          @change="onFileSelected($event, post)"
        />
      </form>
    </b-form-group>








    <b-form-group
      label="Title:"
      label-for="input-1"
    >
      <b-form-input
        id="input-1"
        placeholder="Enter Title"
        v-model="form.Title"
        required
      ></b-form-input>
    </b-form-group>

    <span class="text-dark">Show on Lite version?:</span><br>
 <b-form-select v-model="form.lite" :options="[true,false]"></b-form-select>

    <b-form-group
      label="Body:"
      label-for="input-1"
    >
      <b-form-textarea
        id="input-2"
        placeholder="Enter Body"
        v-model="form.Body"
      ></b-form-textarea>
    </b-form-group>

    <!--   <b-form-group label="Published Date:" label-for="input-2">
      <b-form-input
        id="input-2"
        placeholder="Published Date"
        type="date"
        v-model="form.publisheddate"
   
      ></b-form-input>
    <b-form-input
        id="input-2"
        placeholder="Published Date"
        type="time"
        v-model="form.time"
        required
      ></b-form-input>
    </b-form-group> -->

      <!--     <b-form-group label="New Order:" label-for="input-3">
      <b-form-input
        id="input-3"
        placeholder="New Order"
        type="date"
        v-model="form.NewOrder.nanoseconds"
   
      ></b-form-input>
    <b-form-input
        id="input-2"
        placeholder="Published Date"
        type="time"
        v-model="form.time"
        required
      ></b-form-input>
    </b-form-group> -->

    <b-form-group
      label="Section:"
      label-for="input-3"
    >
      <!-- <b-form-input
        id="input-3"
        placeholder="Section"
        v-model="form.Section"
        required
      ></b-form-input> -->
      <b-form-select
        v-model="form.Section"
        :options="sectionItems"
        :value="form.Section"
        class="mt-3"
        @change="getSubsections()"
      ></b-form-select>

      <!-- <b-dropdown id="dropdown-1" text="Dropdown Button" class="m-md-2">
        <b-dropdown-item v-for="s in sections" v-bind:key="s.id">{{
          s.section
        }}</b-dropdown-item>
      </b-dropdown> -->
    </b-form-group>

    <b-form-group
      label="Subsection:"
      label-for="input-4"
    >
      <b-form-select
        v-model="form.Subsection"
        :options="subsections"
        :value="form.Subsection"
        class="mt-3"
      ></b-form-select>

      <!-- <b-form-input
        id="input-4"
        placeholder="Subsection"
        v-model="form.Subsection"
        required
      ></b-form-input> -->
    </b-form-group>

    <b-form-group
      label="Tagline:"
      label-for="input-5"
    >
      <b-form-input
        id="input-5"
        placeholder="Tagline"
        v-model="form.Tagline"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Document:"
      label-for="input-22"
    >
      <form action="#">
        <input
          id="input-22"
          type="file"
          @change="onDocumentSelected($event, post)"
        /><br />
        {{ form.Document }}
      </form>
    </b-form-group>

    <b-form-group
      label="Video URL:"
      label-for="input-6"
    >
      <b-form-input
        id="input-6"
        placeholder="Video URL"
        v-model="form.Video"
        @blur="getVimeoID(form.Video)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Vimeo ID:"
      label-for="input-7"
      v-if="form.VimeoID"
    >
      {{ form.VimeoID }}
      <!-- <b-form-input
        id="input-7"
        placeholder="Vimeo ID"
        v-model="form.VimeoID"
      ></b-form-input> -->
    </b-form-group>

    <b-form-group
      label="Link URL:"
      label-for="input-8"
    >
      <b-form-input
        id="input-8"
        placeholder="Link URL"
        v-model="form.Link"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Link Label:"
      label-for="input-9"
    >
      <b-form-input
        id="input-9"
        placeholder="Link Label"
        v-model="form.LinkDescription"
      ></b-form-input>
    </b-form-group>
    <hr />
    <b-form-group
      label="Link URL2:"
      label-for="input-8"
    >
      <b-form-input
        id="input-8"
        placeholder="Link URL 2"
        v-model="form.Link2"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Link Label 2:"
      label-for="input-9"
    >
      <b-form-input
        id="input-9"
        placeholder="Link Label"
        v-model="form.LinkDescription2"
      ></b-form-input>
    </b-form-group>
    <hr />
    <b-form-group
      label="Link URL 3:"
      label-for="input-8"
    >
      <b-form-input
        id="input-8"
        placeholder="Link URL"
        v-model="form.Link3"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Link Label 3:"
      label-for="input-9"
    >
      <b-form-input
        id="input-9"
        placeholder="Link Label"
        v-model="form.LinkDescription3"
      ></b-form-input>
    </b-form-group>

    <hr />
    <b-form-group
      label="Link URL 4:"
      label-for="input-8"
    >
      <b-form-input
        id="input-8"
        placeholder="Link URL"
        v-model="form.Link4"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Link Label 4:"
      label-for="input-9"
    >
      <b-form-input
        id="input-9"
        placeholder="Link Label"
        v-model="form.LinkDescription4"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Link URL 5:"
      label-for="input-8"
    >
      <b-form-input
        id="input-8"
        placeholder="Link URL"
        v-model="form.Link5"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Link Label 5:"
      label-for="input-9"
    >
      <b-form-input
        id="input-9"
        placeholder="Link Label"
        v-model="form.LinkDescription5"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Tags:"
      label-for="input-8"
    >
      <div class="row">
        <div
          v-for="(cat, i) in form.Category"
          v-bind:key="i"
          class="col-md-3 p-3 text-danger"
        >
          #{{ cat }}
          <span class="text-secondary"><i
              class="fa fa-trash"
              @click="removeTag(i)"
            ></i></span>
        </div>
        <div class="col-md-3 mt-2">
          <span class="text-primary">#</span><input
            class=""
            placeholder="Add Tag"
            v-model="newTag"
            style="width: 100px; box-sizing: border-box"
            type="text"
          /><i
            @click="addTag()"
            class="text-danger fa fa-plus"
          ></i>
        </div>
      </div>
      <!-- <b-form-group label="Category:" label-for="input-8"> -->
      <!-- <TagInput :prefillTags="form.Category" @changed="onTagsUpdated" /> -->
      <!-- </b-form-group> -->
    </b-form-group>
    <div class="p-3">
      <b-button
        v-if="post && !documentUploading"
        type="submit"
        variant="primary"
        class="float-right"
      >Save</b-button>
      <div
        v-if="post && documentUploading"
        class="btn btn-secondary float-right"
      >
        Uploading...hang on...
      </div>

      <b-button
        type="submit"
        variant="primary"
        v-if="!post"
        class="float-right"
      >Add Post</b-button>
      <b-button
        type="button"
        v-on:click="hide()"
      >Cancel</b-button>
    </div>
  </b-form>
</template>

<script>
const axios = require("axios");
import { db, storage } from "@/db.js";
// import TagInput from "./TagInput";
import date from "date-and-time";
export default {
  components: {  },
  props: ["post", "mode"],
  watch: {
    post: {
      immediate: true,
      handler: "handlePostChange",
    },
  },
  data () {
    return {
      documentUploading: false,
      currentUser: null,
      sections: [],
      sectionItems: [],
      subsections: [],
      posts: [],
      newTag: "",
      form: {
        id: "",
        Body: "",
        Category: [],
        Section: "",
        Subsection: "",
        Tagline: "",
        Title: "",
        Video: "",
        Document: null,
        VimeoID: "",
        date: "",
        time: "",
        Thumbnail: "",
        LinkDescription: "",
        Link: "",
        LinkDescription2: "",
        Link2: "",
        LinkDescription3: "",
        Link3: "",
        LinkDescription4: "",
        Link4: "",
        LinkDescription5: "",
        Link5: "",
        lite: false
      },
    };
  },
  mounted () {
    const _this = this;
    this.$bind("sections", db.collection("sections").orderBy("section")).then(
      function () {
        _this.sections.forEach((s) => {
          //_this.sectionItems.push(s.section.toLowerCase());
          _this.sectionItems.push(s.section);
        });
      }
    );
    this.getSubsections();
  },
  methods: {
    getDate(d){
return Date(d)
    },
    addTag () {
      this.form.Category.push(this.newTag);

      this.newTag = "";
    },

    removeTag (i) {
      this.$delete(this.form.Category, i);
    },

    handlePostChange () {
      if (!this.post) return;

      var publishedDate = this.post.PublishedDate
        ? new Date(this.post.PublishedDate)
        : new Date();
      this.form = {
        ...JSON.parse(JSON.stringify(this.post)),
        time: `${publishedDate.getHours()}:${publishedDate.getMinutes()}`,
        date: `${publishedDate.getFullYear()}-${publishedDate.getMonth() < 10
            ? "0" + publishedDate.getMonth()
            : publishedDate.getMonth()
          }-${publishedDate.getDate()}`,
      };
    },
    onTagsUpdated (categoryTags) {
      console.log("cat", this.form.Category);
      if (this.form.Category.length) {
        this.form.Category.splice(0, this.form.Category.length);
        this.form.Category.push(...categoryTags);
      }
    },
    onSubmit () {
      try {
        const now = new Date();
        let d = date.format(now, "YYYY-MM-DD hh:mm:ss");

           if (!this.form.NewOrder) {
          this.form.NewOrder = null;
        }

        if (!this.form.Document) {
          this.form.Document = null;
        }

        if (!this.form.Link) {
          this.form.Link = null;
        }

        if (!this.form.Link2) {
          this.form.Link2 = null;
        }

        if (!this.form.Link3) {
          this.form.Link3 = null;
        }

        if (!this.form.Link4) {
          this.form.Link4 = null;
        }
        if (!this.form.Link5) {
          this.form.Link5 = null;
        }

        if (!this.form.LinkDescription) {
          this.form.LinkDescription = null;
        }

        if (!this.form.LinkDescription2) {
          this.form.LinkDescription2 = null;
        }
        if (!this.form.LinkDescription3) {
          this.form.LinkDescription3 = null;
        }
        if (!this.form.LinkDescription4) {
          this.form.LinkDescription4 = null;
        }
        if (!this.form.LinkDescription5) {
          this.form.LinkDescription5 = null;
        }
         if (!this.form.lite) {
          this.form.lite = null;
        }

        if (this.mode == "edit") {
          //edit post

          db.collection("posts").doc(this.post.id).update({
            Body: this.form.Body,
            Category: this.form.Category,
         
            Section: this.form.Section,
            Subsection: this.form.Subsection,
            Tagline: this.form.Tagline,
            Title: this.form.Title,
            Video: this.form.Video,
            VimeoID: this.form.VimeoID,
            Document: this.form.Document,
            Link: this.form.Link,
            LinkDescription: this.form.LinkDescription,
            Link2: this.form.Link2,
            LinkDescription2: this.form.LinkDescription2,
            Link3: this.form.Link3,
            LinkDescription3: this.form.LinkDescription3,
            Link4: this.form.Link4,
            LinkDescription4: this.form.LinkDescription4,
            Link5: this.form.Link5,
            LinkDescription5: this.form.LinkDescription5,
            Updated: Date.now(),
            lite: this.form.lite
          });

          this.$bvModal.hide("modal-edit-post");
        } else {
          //new post
          db.collection("posts").add({
            Body: this.form.Body,
            Category: this.form.Category,
            PublishedDate: d,
            NewOrder: d,
            Section: this.form.Section,
            Subsection: this.form.Subsection,
            Tagline: this.form.Tagline,
            Title: this.form.Title,
            Video: this.form.Video,
            VimeoID: this.form.VimeoID,
            Document: this.form.Document,
            Thumbnail: this.form.Thumbnail,
            Link: this.form.Link,
            LinkDescription: this.form.LinkDescription,
            Link2: this.form.Link2,
            LinkDescription2: this.form.LinkDescription2,
            Link3: this.form.Link3,
            LinkDescription3: this.form.LinkDescription3,
            Link4: this.form.Link4,
            LinkDescription4: this.form.LinkDescription4,
            Link5: this.form.Link5,
            LinkDescription5: this.form.LinkDescription5,
               lite: this.form.lite
          });

          this.$bvModal.hide("modal-new-post");
        }
      } catch (err) {
        alert("Error: Screenshot this for Glenn please - " + err);
      }
    },
    onDocumentSelected (event, post) {
      this.documentUploading = true;
      const _this = this;
      const file = event.target.files[0];
      storage
        .ref()
        .child(file.name)
        .put(file)
        .then(async (snapshot) => {
          //get uploaded file url
          const url = await snapshot.ref.getDownloadURL();

          this.form.Document = url;
          // // updating uploaded image url in doc
          // if (this.mode == "edit") {
          //   db.collection("posts").doc(post.id).update({
          //     Document: url,
          //   });
          // }

          // reseting file input
          //event.target.parentElement.reset();
          _this.documentUploading = false;
        });
    },
    onFileSelected (event, post) {
      const file = event.target.files[0];
      storage
        .ref()
        .child(file.name)
        .put(file)
        .then(async (snapshot) => {
          //get uploaded file url
          const url = await snapshot.ref.getDownloadURL();

          this.form.Thumbnail = url;
          // updating uploaded image url in doc
          if (this.mode == "edit") {
            db.collection("posts").doc(post.id).update({
              Thumbnail: url,
            });
          }

          // reseting file input
          event.target.parentElement.reset();
        });
    },
    getSubsections () {
      const _this = this;
      if (this.form.Section) {
        db.collection("sections")
          .where("section", "==", this.form.Section)
          .get()
          .then((querySnapshot) => {
            const documents = querySnapshot.docs.map((doc) => doc.data());

            _this.subsections = documents[0].subsections;
          });
      }
    },
    getVimeoID (video) {
      const _this = this;
      var config = {
        method: "get",
        url: "https://vimeo.com/api/oembed.json?url=" + video,
        headers: {
          Referer: "thedjsvault.com",
        },
      };

      axios(config)
        .then(function (response) {
          _this.form.VimeoID = response.data.video_id;
          console.log(response.data.video_id);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    hide () {
      this.$bvModal.hide("modal-new-post");
      this.$bvModal.hide("modal-edit-post");
    },
  },
};
</script>

<style>

</style>