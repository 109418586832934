<template>
  <b-form @submit.prevent="onSubmit">
    <b-form-group
      label="Thumbnail:"
      label-for="input-0"
    >
      <img
        style="width: 200px"
        v-bind:src="form.image"
      /><br /><br />
      <form action="#">
        <input
          type="file"
          @change="onImageSelected($event)"
        />
      </form>
    </b-form-group>

    <b-form-group
      label="Title:"
      label-for="input-1"
    >
      <b-form-input
        id="input-1"
        placeholder="Enter Title"
        v-model="form.title"
        required
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Date:"
      label-for="input-2"
    >
      
            <b-form-datepicker id="example-datepicker" v-model="form.date" class="mb-2"></b-form-datepicker>
    </b-form-group>

 

   
    <div class="p-3">


      <b-button
      @click="addEvent()"
        variant="primary"
   
        class="float-right"
      >Add</b-button>
      <b-button
        type="button"
        v-on:click="hide()"
      >Cancel</b-button>
    </div>
  </b-form>
</template>

<script>
const axios = require("axios");
import { db, storage } from "@/db.js";
import { Timestamp } from 'firebase'

import date from "date-and-time";
export default {
  components: {  },
  props: ["mode"],
 

  data () {
    return {
      documentUploading: false,
      currentUser: null,



      form: {
        id: "",
       image: "",
       title: "",
       date: null,
      },
    };
  },
  mounted () {
   
  },
  methods: {
   
   
    addEvent () {
      try {
    // let enteredDate = new Date(this.form.date)();
    //     let d = date.format(enteredDate, "YYYY-MM-DD hh:mm:ss");


var myTimestamp = Timestamp;


      
          // //new post
          // db.collection("events2").add({
          //   title: this.form.title,
          //   image: this.form.image,
          //   date: Timestamp.fromDate(new Date()),
          //   description: "",
          //   link: "",
            
          // });

          this.$bvModal.hide("modal-new-event");
      
      } catch (err) {
        alert("Error: Screenshot this for Glenn please - " + err);
      }
    },
   
    onImageSelected (event) {
      const file = event.target.files[0];
      storage
        .ref()
        .child(file.name)
        .put(file)
        .then(async (snapshot) => {
          //get uploaded file url
          const url = await snapshot.ref.getDownloadURL();


     this.form.image = url;

        
      
          // // updating uploaded image url in doc
          // if (this.mode == "edit") {
          //   db.collection("posts").doc(post.id).update({
          //     Thumbnail: url,
          //   });
          // }

          // reseting file input
          // event.target.parentElement.reset();
        });
    },
    getSubsections () {
      const _this = this;
      if (this.form.Section) {
        db.collection("sections")
          .where("section", "==", this.form.Section)
          .get()
          .then((querySnapshot) => {
            const documents = querySnapshot.docs.map((doc) => doc.data());

            _this.subsections = documents[0].subsections;
          });
      }
    },
    getVimeoID (video) {
      const _this = this;
      var config = {
        method: "get",
        url: "https://vimeo.com/api/oembed.json?url=" + video,
        headers: {
          Referer: "thedjsvault.com",
        },
      };

      axios(config)
        .then(function (response) {
          _this.form.VimeoID = response.data.video_id;
          console.log(response.data.video_id);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    hide () {
   
      this.$bvModal.hide("modal-new-event");
    },
  },
};
</script>

<style>
</style>