<template>
  <div class="container-fluid">
    <!-- <br /><button @click="createUsers()">Create User</button> -->

    <b-tabs
      content-class="mt-"
      v-if="adminLoggedIn"
    >
      <b-tab
        title="Posts"
        active
      >
        <!-- <button @click="fixDates()">FIX Dates</button> -->
        <div class="row p-3">
          <div class="col-md-4">
            <button
              class="btn btn-danger"
              @click="showNewModal()"
            >
              Add New
            </button>
          </div>
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>
          </div>
        </div>

        <b-table
          striped
          hover
          :items="posts"
          :fields="fields"
          :current-page="currentPage"
          :per-page="500"
          :filter="filter"
          :filter-included-fields="filterOn"
        >
          <template #cell(Thumbnail)="row">
            <img
              style="width: 100px"
              v-bind:src="row.item.Thumbnail"
            />
          </template>
          <template #cell(Viewed)="row">

            <span v-if="row.item.Viewed">{{row.item.Viewed.length}}</span>

          </template>
          <template #cell(id)="row">
            <!-- <form action="#">
          <input type="file" @change="onFileSelected($event, row.item)" />
        </form> -->
            <i
              class="fa fa-edit fa-2x"
              @click="showEditModal(row.item)"
            />
            <i
              class="fa fa-trash fa-2x text-danger"
              @click="deletePost(row.item)"
            />
            <!-- <b-button @click="showEditModal(row.item)">Edit</b-button> -->
          </template>
        </b-table>

        <!-- <b-pagination
      v-model="currentPage"
      :total-rows="posts.length"
      align="fill"
      size="sm"
    ></b-pagination> -->

        <b-modal
          id="modal-new-event"
          title="New Event"
          size="lg"
        >
          <EventForm :mode="'new'" />
          <template slot="FOOT_b">
            <!--removed  slot-scope="scope"-->
            <span>New</span>
          </template>
        </b-modal>

        <b-modal
          id="modal-new-post"
          title="New Post"
          size="lg"
        >
          <PostForm
            :post="null"
            :mode="'new'"
          />
          <template slot="FOOT_b">
            <!--removed  slot-scope="scope"-->
            <span>New</span>
          </template>
        </b-modal>

        <b-modal
          id="modal-edit-post"
          title="Edit Post"
          size="lg"
        >
          <PostForm
            :post="currentPost"
            :mode="'edit'"
          />
          <template slot="FOOT_b">
            <!--removed  slot-scope="scope"-->
            <span>Edit</span>
          </template>
        </b-modal>
      </b-tab>
      <b-tab title="Users">
        <user-admin></user-admin>
      </b-tab>
      <b-tab title="Events">
        <br />
        <!-- 
        <div v-for="(event,i) in events" :key="i">
{{event.order}} - {{event.month}} - {{event.day}} - {{event.time}} - {{event.details}}
        </div> -->

        <div
          class="btn btn-danger"
          @click="showNewEventModal()"
        >Add Event</div>

        <!-- <div v-if="addEvent">
          <div class="row">
           
            <div class="col-md-4">
           Date:
            <b-form-datepicker id="example-datepicker" v-model="newEvent.date" class="mb-2"></b-form-datepicker>
            </div>
            <div class="col-md-7">
              Title:<b-form-input
                type="text"
                v-model="newEvent.title"
              ></b-form-input>
            </div>
            <div class="col-md-1">
              <br /><button
                @click="addNewEvent()"
                class="btn btn-danger"
              >
                Add
              </button>
            </div>
          </div>

          <br /><br />
        </div> -->

        <b-table
          striped
          hover
          :items="events"
          :fields="eventFields"
        >

          <template #cell(image)="data">
            <img
              style="width: 100px"
              :src="data.value"
            />
          </template>

          <template #cell(title)="data">
            {{data.value}}
          </template>

          <template #cell(date)="data">
            <!-- {{getDate(data.value)}} -->
            {{data}}
          </template>

        </b-table>

        <!-- <b-table
          striped
          hover
          :items="events"
          :fields="eventFields"
        >
          <template #cell(order)="data">
            <b-form-input
              v-if="selectedRow[data.index]"
              type="text"
              v-model="events[data.index].order"
            ></b-form-input>
            <span
              class="text-warning label"
              v-if="selectedRow[data.index]"
            >Order - Low to High</span>
            <span v-else>{{ data.value }}</span>
          </template>

          <template #cell(time)="data">
            <b-form-input
              v-if="selectedRow[data.index]"
              type="text"
              v-model="events[data.index].time"
            ></b-form-input>
            <span
              class="text-warning label"
              v-if="selectedRow[data.index]"
            >ex: 8pm EST</span>
            <span v-else>{{ data.value }}</span>
          </template>

          <template #cell(month)="data">
            <b-form-input
              v-if="selectedRow[data.index]"
              type="text"
              v-model="events[data.index].month"
            ></b-form-input>
            <span
              class="text-warning label"
              v-if="selectedRow[data.index]"
            >3 letters - ex: JUN</span>
            <span v-else>{{ data.value }}</span>
          </template>

          <template #cell(day)="data">
            <b-form-input
              v-if="selectedRow[data.index]"
              type="text"
              v-model="events[data.index].day"
            ></b-form-input>
            <span
              class="text-warning label"
              v-if="selectedRow[data.index]"
            >2 digits - ex: 01</span>
            <span v-else>{{ data.value }}</span>
          </template>

          <template #cell(details)="data">
            <b-form-input
              v-if="selectedRow[data.index]"
              type="text"
              v-model="events[data.index].details"
            ></b-form-input>
            <span v-else>{{ data.value }}</span>
          </template>
          <template #cell(edit)="data">
            <b-button
              @click="handleEditRow(data)"
              v-if="!selectedRow[data.index]"
            >
              <span>Edit</span>
            </b-button>

            <b-button
              @click="handleSaveRow(data)"
              v-if="selectedRow[data.index]"
            >
              <span>Done</span>
            </b-button>
          </template>

          <template #cell(delete)="data">
            <i
              class="fa fa-trash fa-2x text-danger"
              @click="deleteEvent(data)"
            ></i>
          </template>
        </b-table> -->
      </b-tab>
      <b-tab title="Paths">

      </b-tab>
      <!-- 
      <b-tab title="Suggestions">
        <div v-for="suggestion in suggestions" :key="suggestion.id">
          <b>Date:</b> {{ suggestion.date.toDate() }}<br />
          <b>Name:</b> {{ suggestion.name }}<br />
          <b>Email:</b> {{ suggestion.email }}<br />
          <b>Suggestion:</b> {{ suggestion.suggestion }}
          <hr />
        </div>
      </b-tab> -->
    </b-tabs>
  </div>
</template>

<script>
import { db, storage } from "@/db.js";
import PostForm from "../components/PostForm";
import EventForm from "../components/EventForm";
import UserAdmin from "../components/UserAdmin.vue";
// import Datepicker from "vuejs-datepicker";
import _ from "lodash";
import usersData from "/members.json";
import firebase from "firebase"


const request = require("request");
import moment from "moment";
const axios = require("axios");
export default {
  name: "Home",
  components: { PostForm, UserAdmin, EventForm },
  data: function () {
    return {
      selectedRow: 0,
      newEvent: {
        order: null,
        day: null,
        month: null,
        time: null,
        details: null,
      },
      addEvent: false,
      eventFields: [

        {
          key: "image",

          label: "Image",
        }, {
          key: "title",

          label: "Title",
        },
        {
          key: "date",

          label: "Date",
        },

        { key: "edit", label: "" },
        { key: "delete", label: "" },
      ],
      fields: [
        {
          key: "Thumbnail",
          sortable: true,
          label: "",
        },

        {
          key: "Title",
          sortable: true,
        },

        {
          key: "Section",
          sortable: true,
        },
        {
          key: "Subsection",
          sortable: true,
        },
        {
          key: "lite",
          sortable: true,
        },
        //    {
        //   key: "Document",
        //   sortable: true,
        // },
        {
          key: "Viewed",
          label: "Viewed",
          sortable: true,
        },

        {
          key: "id",
          label: "Actions",
          sortable: false,
        },
      ],
      posts: [],
      events: [],
      currentPage: 1,
      currentPost: {},
      filter: "",
      filterOn: ["Title"],
      existingUsers: usersData,
      users: [],
      suggestions: [],
      adminLoggedIn: false,
    };
  },
  mounted () {

    //     let pw = window.prompt("Please enter the password")

    //     if (pw == 'BunnNC') {
    this.adminLoggedIn = true;
    this.getPosts();
    // } else {
    //    this.adminLoggedIn = false;

    // }

  },
  methods: {
    async addNewEvent () {
      const res = await db.collection("events2").add(this.newEvent);
    },
    deleteEvent (data) {
      let self = this;
      db.collection("events2")
        .doc(data.item.id)
        .delete()
        .then(() => {
          this.$bind("events", db.collection("events2")).then(function () {
            var sortedObjs = _.sortBy(this.events, "order");
            // console.log(this.events, sortedObjs);
            this.events = sortedObjs;
          });
        });
    },
    
    handleSaveRow (data) { 
      db.collection("events2")
        .doc(data.item.id)
        .update({
          month: data.item.month,
          day: data.item.day,
          time: data.item.time,
          details: data.item.details,
          order: data.item.order,
        })
        .then(() => {
          this.selectedRow = 0;
          console.log("SAVED", data.item);
        });
    },
    handleEditRow (data) {
      this.selectedRow = {
        [data.index]: !this.selectedRow[data.index],
      };
    },
    createUsers () {
      usersData.slice(1000, 1500).forEach(function (user) {
        setTimeout(function () {
          addUser(user);
        }, 1000);
      });

      function addUser (user) {
        const _this = this;
        var options = {
          method: "POST",
          url:
            "https://us-central1-thedjsvault-307019.cloudfunctions.net/app/api/kartra",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            lead: {
              first_name: user.FirstName,

              last_name: user.LastName,

              email: user.Email,

              phone: "",
            },
            action: "membership_granted",
            action_details: {
              membership: {
                level_name: user.Membership,
              },
            },
          }),
        };
        request(options, function (error, response) {
          if (error) {
            console.error("ERROR:" + user.Email, error);
          }
          // console.log(response);
          //console.log(response.body);
        });
      }
    },
    getPosts () {
      const _this = this;
      this.$bind(
        "posts",
        db.collection("posts").orderBy("PublishedDate", "desc")
      ).then(function () { });

      this.$bind("admins", db.collection("admins")).then(function () { });

      let self = this;
      const d = new Date();
      this.$bind("events", db.collection("events2").orderBy("date").startAt(d)).then(function () {

        var sortedObjs = _.sortBy(self.events, "order");
        // console.log(self.events, sortedObjs);
        self.events = sortedObjs;
      });

      this.$bind(
        "suggestions",
        db.collection("suggestions")
      ).then(function () { });
    },
    // onFileSelected(event, post) {
    //   const file = event.target.files[0];
    //   storage
    //     .ref()
    //     .child(file.name)
    //     .put(file)
    //     .then(async (snapshot) => {
    //       //get uploaded file url
    //       const url = await snapshot.ref.getDownloadURL();

    //       // updating uploaded image url in doc
    //       db.collection("posts").doc(post.id).update({
    //         Image: url,
    //       });

    //       // reseting file input
    //       event.target.parentElement.reset();
    //     });
    // },
    showNewModal () {
      this.$bvModal.show("modal-new-post");
    },
    showNewEventModal () {
      this.$bvModal.show("modal-new-event");
    },
    showEditModal (post) {
      console.log({ post });
      this.currentPost = post;
      this.$bvModal.show("modal-edit-post");
    },
    deletePost (post) {
      if (confirm("Do you really want to delete?")) {
        db.collection("posts")
          .doc(post.id)
          .delete();
      }
    },
    fixDates () {
      this.posts.forEach((post) => {

        if (typeof post.NewOrder === 'string') {
          console.log(post.Title)
          // console.log(this.getDate(post.PublishedDate))



          var myTimestamp = firebase.firestore.Timestamp.fromDate(new Date('01/01/2023'));
          // console.log("Current", myTimestamp)
          db.collection("posts")
                .doc(post.id)
                .update({
                  NewOrder: myTimestamp
                });


        }




        // if (post.NewOrder) {
        //   console.log(post)
        // } else {
        //   try {
        //     if (moment(post.PublishedDate).format("MMMM Do YYYY, h:mm:ss a") !== 'Invalid date') {
        //       console.log("pubDate", moment(post.PublishedDate))
        //       // db.collection("posts")
        //       //   .doc(post.id)
        //       //   .update({
        //       //     NewOrder: moment(post.PublishedDate).format("MMMM Do YYYY, h:mm:ss a").serverTimestamp()
        //       //   });

        //     } else {
        //       console.log("NO DATE")
        //     }

        //   } catch (error) {
        //     console.error("err:", error);

        //   }



        // }



      });
    },
    getDate (d) {

      console.log(d)
      // let newD = d.toDate()
      return moment(d).format("MMMM Do YYYY, h:mm:ss a");
      // return d.toDate;

    },

  },
};
</script>

<style>
.form-group label,
.modal-title {
  color: #000;
}

.modal-footer {
  display: none;
}

.label {
  font-size: 12px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #777777;
  border: thin solid #ffffff;
}
</style>
