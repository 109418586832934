<template>
  <div>
    <b-table striped hover :items="users" :fields="fields" :per-page="2000">
    </b-table>
  </div>
</template>

<script>
const request = require("request");
const lodash = require("lodash");
export default {
  data() {
    return {
      users: [],
      fields: [
        "displayName",
        "email",
       // "customClaims.membershipLevel",
        "customClaims.active",
      ],
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      const _this = this;
      request(
         "https://us-central1-thedjsvault-307019.cloudfunctions.net/app/api/users",
       // "http://localhost:5001/thedjsvault-307019/us-central1/app/api/users",
        function (error, response) {
          // console.error('error:', error); // Print the error if one occurred
          //console.log('statusCode:', response && response.statusCode); // Print the response status code if a response was received
         
         _this.users = [...JSON.parse(response.body)[0].users,...JSON.parse(response.body)[1].users]

        //  console.log("Firebase Users: ", _this.users)

         
        }
      );
    },

   
  },
};
</script>

<style>
</style>